<template>
    <FrontendLayout title="One-Stop A.I. Call Platform That Connects, Engages, and Converts.">
        <WelcomeHero>
            <template #title>
                The
                <u>One-Stop</u>
                A.I. Call Platform For Your Business.
            </template>

            <template #text>
                Create human-like A.I. call experiences for your business with a few clicks.
                Craft intelligent workflows, collect caller information, schedule appointments, and much more.
            </template>
        </WelcomeHero>

        <!--                <HomepageVideo/>-->

        <DemoCallLeadForm/>

<!--        <div class="container mx-auto gm-padding-y">-->
<!--            <h2 class="text-center mb-2">-->
<!--                Listen To Sample Calls.-->
<!--            </h2>-->
<!--            <p class="text-center text-lg mb-8">-->
<!--                <b>Unmodified</b> and <b>uncut</b> sample calls from Greetmate, directly taken from actual phone calls.-->
<!--            </p>-->

<!--            <CallExamplePlayer/>-->

<!--            <div class="text-center mt-6">-->
<!--                <StartTrialBusinessButton/>-->
<!--            </div>-->
<!--        </div>-->

        <IntroSlogan/>

        <!--        <NumCalls :calls="calls"/>-->
        <Voices/>

        <ForEveryone/>

        <FeatureBox :items="featureItems"/>

        <FeatureTabs/>

        <DiscoverGreetmate/>

        <FeatureCards/>

        <Integrations/>

        <Pricing/>

    </FrontendLayout>
</template>
<script setup lang="ts">
import FrontendLayout from "@/Layouts/FrontendLayout.vue";
import WelcomeHero from "@/Pages/Index/WelcomeHero.vue";
import Voices from "@/Pages/Index/Voices.vue";
import FeatureTabs from "@/Pages/Index/FeatureTabs.vue";
import Pricing from "@/Pages/Index/Pricing.vue";
import HomepageVideo from "@/Pages/Index/HomepageVideo.vue";
import IntroSlogan from "@/Pages/Index/IntroSlogan.vue";
import DiscoverGreetmate from "@/Pages/Index/DiscoverGreetmate.vue";

import calls from '@/../images/calls.jpg?w=1900&format=webp';
import phonenumber from '@/../images/phone-number.jpg?w=1000&format=webp';
import instructions from '@/../images/instructions.jpg?w=1000&format=webp';
import workflow1 from '@/../images/workflow1.jpg?w=1000&format=webp';

import ForEveryone from "@/Pages/Index/ForEveryone.vue";
import FeatureCards from "@/Pages/Index/FeatureCards.vue";
import FeatureBox from "@/Components/Marketing/FeatureBox.vue";
import Integrations from "@/Pages/Index/Integrations.vue";
import CallExamplePlayer from "@/Components/Marketing/CallExamplePlayer.vue";
import StartTrialBusinessButton from "@/Components/Marketing/StartTrialBusinessButton.vue";
import FacePlayer from "@/Components/Marketing/FacePlayer.vue";
import FaceRecordingPlayer from "@/Components/Marketing/FaceRecordingPlayer.vue";
import AppBadge from "@/Components/AppBadge.vue";
import DemoCallLeadForm from "@/Components/Marketing/DemoCallLeadForm.vue";


defineProps(['calls'])

const featureItems = [
    {
        title: 'Let Greetmate do the Calling for You and Save Hundreds of Hours.',
        content: 'No time to take all your calls? Let Greetmate do the hard work for you. Greetmate can handle all your calls, answer questions, take orders, and even transfer calls to you when needed. It operates 24/7 and never gets tired.',
        image: phonenumber,
        color: 'bg-green-500/10'
    },
    {
        title: 'Qualify Callers and Filter out Spam.',
        content: 'Greetmate can ask questions to qualify your callers, filter out spam calls, and even send the information to your CRM or other systems. You can even use the information to route calls to the right person or department.',
        image: instructions
    },
    {
        title: 'A Fraction of the Cost of a Human Agent.',
        content: 'Greetmate can handle all your calls at a fraction of the cost of a human agent.',
        color: 'bg-yellow-500/10',
        image: workflow1
    },
]

</script>
